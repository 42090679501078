import React from "react"
import { Link } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Footer = ({ app }) => {
  const breakpoints = useBreakpoint()

  return (
    <>
      {!app && (
        <section className="section text-dark bg-gradient-secondary py-4 text-center">
          <div className="container">
            <div className="row gap-y">
              <div className="col-md-12">
                <p className="fs-14 text-left mt-6 mb-0">
                  * Funds are generally deposited via ACH for delivery next
                  business day after approval if approved by 2pm Eastern
                  Monday-Friday.
                </p>
                <p className="fs-14 text-left mt-2 mb-0">
                  Rates and Terms for Guarantor Personal Loan: Loan amounts
                  range from $1,100 to $3,000. APR ranges from 9.95% to 35.99%.
                  Loan length is 36 months. Origination fee up to 5%.
                </p>
                <p className="fs-14 text-left mt-2 mb-0">
                  A representative example: You receive a loan of $1,500
                  repayable over 36 monthly installments with an interest rate
                  of 15% and a 5% origination fee of $75, for an APR of 18.67%.
                  In this example, $1,425 will be given to your guarantor as
                  your agent and you will make 36 monthly payments of $52.
                </p>
                <p className="fs-14 text-left mt-2 mb-0">
                  Checking your loan rate generates a soft credit inquiry on
                  your credit report, which is visible only to you. A hard
                  credit inquiry, which is visible to you and others, and which
                  may affect your credit score, only appears on your credit
                  report if and when a loan is issued to you.
                </p>
                <p className="fs-14 text-left mt-2 mb-0">
                  What you need to know about applying for a loan with Trustic:
                </p>
                <ul className="fs-14 mt-2 text-left">
                  <li>
                    To help the government fight the funding of terrorism and
                    money laundering activities, federal law requires all
                    financial institutions to obtain, verify, and record
                    information that identifies each person who opens an
                    account.
                  </li>
                  <li>
                    When you open an account, we will ask for your name, date of
                    birth, address and other information that will allow us to
                    identify you.
                  </li>
                  <li>
                    As part of the application, you will have to nominate a
                    Guarantor. A Guarantor can be a family member or a friend
                    who will provide a personal guarantee for your loan.
                  </li>
                  <li>
                    Once the loan is finalised, the loan proceeds will be
                    disbursed to your Guarantor who will act as your agent. You
                    should not take this loan unless you trust your Guarantor to
                    forward the loan proceeds to you.
                  </li>
                </ul>
                <p className="fs-14 text-left mt-2 mb-0">
                  Unless otherwise specified, all loans are provided by Nextate
                  Credit, LLC, NMLS ID 2050326.
                </p>
                <p className="fs-14 text-left mt-2 mb-6">
                  “Trustic” is a trademark of Nextate Corporation.
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
      <footer
        className="footer text-white bg-dark"
        // style={{ backgroundImage: "url(../assets/img/vector/9.jpg)" }}
        data-overlay={8}
      >
        {!app && (
          <>
            <div className="container py-7">
              <div
                className="row gap-y fs-12"
                style={{ textTransform: "uppercase" }}
              >
                <div className="col-12 col-md-3 col-xl-3">
                  <h5 className="mb-4">
                    <strong>Our Loans</strong>
                  </h5>
                  <div className="nav flex-column">
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/guarantor-personal-loans"
                    >
                      Guarantor Personal Loans
                    </Link>
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/"
                    >
                      Apply for a Loan
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-xl-3">
                  <h5 className="mb-4">
                    <strong>Company</strong>
                  </h5>
                  <div className="nav flex-column">
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/about"
                    >
                      About
                    </Link>
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/blog"
                    >
                      Blog
                    </Link>
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/contact"
                    >
                      Contact
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-xl-3">
                  <h5 className="mb-4">
                    <strong>Q&A</strong>
                  </h5>
                  <div className="nav flex-column">
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/hub"
                    >
                      Q&A Hub
                    </Link>
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/hub"
                    >
                      Applying for a Loan
                    </Link>
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/hub"
                    >
                      Finding a Guarantor
                    </Link>
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/hub"
                    >
                      Becoming a Guarantor
                    </Link>
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/hub"
                    >
                      Rates & Terms
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-xl-3">
                  <h5 className="mb-4">
                    <strong>Legal</strong>
                  </h5>
                  <div className="nav flex-column">
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/terms"
                    >
                      Terms of Use
                    </Link>
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/privacy"
                    >
                      Privacy Policy
                    </Link>
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/licenses"
                    >
                      Licenses
                    </Link>
                    <a
                      className="nav-link fw-600"
                      href="https://www.nmlsconsumeraccess.org/"
                      target="_blank"
                    >
                      NMLS Consumer Access
                    </a>
                    <Link
                      className="nav-link fw-600"
                      activeClassName="active"
                      to="/accessibility"
                    >
                      Accessibility
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </>
        )}
        <div className="container">
          <div className="row gap-y">
            <div className="col-md-6 text-center text-md-left">
              <small className="opacity-70">
                © 2021 Nextate Credit, LLC. All rights reserved.
              </small>
            </div>
            {app ? (
              <div className="col-md-6 text-center text-md-right">
                {breakpoints.md ? (
                  <div
                    className="text-white d-flex flex-column justify-content-end align-items-center"
                    style={{
                      minWidth: "130px",
                    }}
                  >
                    <Link to="/hub" style={{ textDecoration: "underline" }}>
                      Need Help?
                    </Link>
                    <strong>support@trustic.com</strong>
                  </div>
                ) : (
                  <div
                    className="text-white d-flex flex-row justify-content-end align-items-center"
                    style={{
                      minWidth: "130px",
                    }}
                  >
                    <Link
                      to="/hub"
                      style={{
                        textDecoration: "underline",
                        marginRight: "15px",
                      }}
                    >
                      Need Help?
                    </Link>
                    <strong>support@trustic.com</strong>
                  </div>
                )}
              </div>
            ) : (
              <div className="col-md-6 text-center text-md-right">
                <div className="social">
                  <a className="social-facebook" href="#">
                    <i className="fa fa-facebook" />
                  </a>
                  <a className="social-twitter" href="#">
                    <i className="fa fa-twitter" />
                  </a>
                  <a className="social-youtube" href="#">
                    <i className="fa fa-youtube" />
                  </a>
                  <a className="social-instagram" href="#">
                    <i className="fa fa-instagram" />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
