import * as React from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const GeneralNav = ({ home, dark, app }) => {
  const openNav = () => {
    document.body.classList.add("navbar-open")
    let backdrop = document.createElement("div")

    backdrop.classList.add("backdrop", "backdrop-navbar")

    document.getElementsByClassName("navbar") &&
      document.getElementsByClassName("navbar")[0] &&
      document.getElementsByClassName("navbar")[0].appendChild(backdrop)
  }

  const closeNav = () => {
    document.body.classList.remove("navbar-open")
    document.getElementsByClassName("backdrop-navbar") &&
      document.getElementsByClassName("backdrop-navbar")[0] &&
      document.getElementsByClassName("backdrop-navbar")[0].remove()
  }

  const breakpoints = useBreakpoint()

  return (
    <nav
      className={
        breakpoints.md
          ? `navbar navbar-expand-lg navbar-stick-dark navbar-dark bg-dark`
          : `navbar navbar-expand-lg navbar-stick-light navbar-light bg-dark`
      }
      data-navbar="fixed"
    >
      <div className="container">
        <div className="navbar-left">
          {!app && (
            <button className="navbar-toggler" type="button" onClick={openNav}>
              <span className="navbar-toggler-icon" />
            </button>
          )}
          <Link className="navbar-brand" to="/">
            <h4 style={{ color: "#fff" }} className="mb-0 fw-900">
              TRUSTIC
            </h4>
          </Link>
        </div>

        {!app && (
          <section className="navbar-mobile justify-content-start justify-content-lg-end lead fs-13">
            <ul className="nav nav-navbar">
              <li className="nav-item">
                <a href="#" className="nav-link">
                  Our Loans <span className="arrow"></span>
                </a>
                <ul className="nav mw-250">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/guarantor-personal-loans"
                      onClick={closeNav}
                    >
                      Guarantor Personal Loans
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  Company <span className="arrow"></span>
                </a>
                <ul className="nav">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/about"
                      onClick={closeNav}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/contact"
                      onClick={closeNav}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  Q&A <span className="arrow"></span>
                </a>
                <ul className="nav mw-200">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/hub"
                      onClick={closeNav}
                    >
                      Q&A Hub
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/hub"
                      onClick={closeNav}
                    >
                      Applying for a Loan
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/hub"
                      onClick={closeNav}
                    >
                      Finding a Guarantor
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/hub"
                      onClick={closeNav}
                    >
                      Becoming a Guarantor
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/hub"
                      onClick={closeNav}
                    >
                      Rates & Terms
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClassName="active"
                  to="/blog"
                  onClick={closeNav}
                >
                  Blog
                </Link>
              </li>
            </ul>
          </section>
        )}
        {app ? (
          <div
            className="text-white d-flex flex-row justify-content-center align-items-center"
            style={{
              minWidth: "130px",
            }}
          >
            <Link
              to="/hub"
              style={{ textDecoration: "underline", marginRight: "15px" }}
            >
              Questions?
            </Link>
            <strong>
              <i className="fa fa-phone-square" aria-hidden="true"></i>{" "}
              888-429-0480
            </strong>
          </div>
        ) : (
          <>
            <Link
              className="btn btn-md btn-rounded btn-danger d-none d-lg-block show-cta"
              to="/apply"
              onClick={e => {
                e.preventDefault()
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
                navigate("/")
              }}
            >
              Check your rate
            </Link>
            <Link
              className="ml-3 btn btn-md btn-rounded btn-outline-light hide-login"
              to="/apply/login"
            >
              Log In
            </Link>
          </>
        )}
      </div>
    </nav>
  )
}

const Header = ({ home, app, dark, headerComponent }) => {
  return (
    <>
      <GeneralNav dark={dark} home={home} app={app} />
      {headerComponent && headerComponent}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
